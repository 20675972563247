@mixin fill-vert
{
	position 	: absolute;
	top 		: 0px;
	bottom 		: 0px;
}

@mixin fit-left-width( $width )
{
	@include 	fill-vert;
	left 		: 0px;
	width 		: $width;
}

@mixin fit-left-to( $to )
{
	@include 	fill-vert;
	left 		: 0px;
	right 		: $to;
}

@mixin fit-right-width( $width )
{
	@include 	fill-vert;
	right 		: 0px;
	width 		: $width;
}

@mixin fit-right-from( $from )
{
	@include 	fill-vert;
	right 		: 0px;
	left 		: $from;
}


@mixin fill-horiz
{
	position 	: absolute;
	left  		: 0px;
	right 		: 0px;
}

@mixin fit-top-height( $height )
{
	@include 	fill-horiz;
	top 		: 0px;
	height 		: $height;
}

@mixin fit-top-to( $to )
{
	@include 	fill-horiz;
	top 		: 0px;
	bottom 		: $to;
}

@mixin fit-bottom-height( $width )
{
	@include 	fill-horiz;
	height 		: $height;
	bottom 		: 0px;
}

@mixin fit-bottom-from( $from )
{
	@include 	fill-horiz;
	top 		: $from;
	bottom 		: 0px;
}



@mixin fill
{
	position 	: absolute;
	top 		: 0px;
	bottom 		: 0px;
	left  		: 0px;
	right 		: 0px;
}


@mixin fill-with-scrollbars
{
	position 	: absolute;
	top 		: 0px;
	bottom 		: 15px;
	left  		: 0px;
	right 		: 15px;
}


@mixin fill-shadowbox
{
	position 	: absolute;
	top 		: 10px;
	bottom 		: 15px;
	left  		: 15px;
	right 		: 15px;
	box-shadow  : 2px 2px 4px #555;
	padding 	: 10px;
	background-color : white;
}



@mixin margin-shadowbox-new
{
	box-shadow  : 2px 2px 4px #aaa;
}


@mixin margin-shadowbox
{
	margin 		: 10px 10px;
	box-shadow  : 3px 3px 2px #555;
	background-color : white;
}



