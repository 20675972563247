$key-color : 			#ff261a;

$main-background:		 	white;
$bottom-background:			#ddd;

$main-submenu-background 	: #445;
$main-submenu-foreground 	:white;


$left-background: 			$main-submenu-background;
$left-background-selected:  $key-color;

$top-height: 				45px;
$menu-height: 				3em;

$menu-background: 			#445;
$menu-background-light: 	#888;
$menu-foreground: 			white;
$menu-border:   			#eee;
$menu-border-width: 		0px;


$menu-background-active: 	#445;
$menu-foreground-active: 	white;


$header-height: 			2.8rem;
$header-height-high-header:  6rem;

$cell-width: 			160px;

$cell-height: 			2.5rem;
$cell-height-coarse: 	2.8rem;

$tree-width: 			820px;
$tree-width-narrow: 	400px;
$chart-width: 			60%;

$top-head-background: 	#585870;

$row-head-background: 	#f8f8f8;
$row-head-foreground: 	#444;

$main-menu-width: 		19em;

@import 'stylesheets/layout';
@import 'stylesheets/form';
@import 'stylesheets/test';


* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

b
{
	font-weight 	: 700;
}

[data-tip]:hover::before {
    content 		: attr(data-tip) ;
    font-size 		: 0.9em;
    position 		: absolute;
    z-index 		: 999;
    white-space 	: nowrap;
	top 			: 35px;
    background  	: #f8f8f8;
    border 	    	: 1px solid #888;
    box-shadow      : 2px 2px 4px #444;
    color 			: #000;
    left 			: -1.5em;
    padding 		: 0.2em 0.5em;
    font-weight 	: 400;
    line-height 	: 24px;
    height 			: 1.5em;

    opacity 		: 1;
    transition 		: opacity 5s ease-out;
    }

.cell[data-tip].haserrors.focused:hover::before
{
	opacity 		: 0;
}


@media (any-pointer: coarse)
{
	.cell
	{
		background-color 	: #fff0f0;
	}

	.cell-contents
	{
		height 	: $cell-height-coarse;
	}



	.cell.row-head
	{
		background-color 	: #503;
	}

	.app-frame
	{
		font-size 	: 1.1em;
	}
}


body
{
	font-family 			: 'Sarabun', sans-serif;
	font-weight 			: 600;
	font-size 				: 0.9em;
	background-color 		: $main-background;
	touch-action 			: none;
}

.app-frame
{
	@include 		fill;
	display 		: flex;
	flex-flow 		: column nowrap;

	.menu
	{
		background 	: $menu-background;
		color 		: $menu-foreground;
		font-size 		: 1em;
		font-weight 	: 700;
		overflow 		: hidden;
		border-bottom   : $menu-border-width solid $menu-border;
		position 		: relative;


		display 		: flex;
		flex-flow 		: row nowrap;
		



		.province-selector
		{
			display 		: flex;
			flex-flow 		: row nowrap;
			align-items 	: baseline;

			width 			: 28em;
			min-width  		: 28em;
			padding 		: 0.3em 1em;

			flex-grow 		: 0;


			.province-caret
			{
				display 		: inline-block;
				font-size 		: 1.6em;
				margin-right 	: 0em;
			}

			.home
			{
				display 		: inline-block;
				font-size 		: 1.6em;
				margin-right 	: 0.5em;
			}


			select
			{
				display 	: inline-block;
				font-size 	: 1.5em;
			}

		}


		.infosection
		{
			flex-grow 		: 1;
			display 		: flex;
			flex-flow 		: column nowrap;
			margin-top 		: 0.2em;

			text-align 		: right;
		}

		.toolbuttons
		{
			margin-top 		: 0.3em;
			flex-grow 		: 0;
			padding-right 	: 1em;
		}

		.info
		{
			margin-top 			: 0.4em;
			padding-top 		: 0.5em;
			margin-bottom 		: 0.8em;
			flex-grow 		: 1;
			text-align 		: right;
			color 			: #eef;
			border-top 		: 1px solid #666;
			font-size 		: 0.9em;

			display 		: flex;
			flex-flow 		: row nowrap;
			justify-content : flex-end;

			.infoblock
			{
				display 		: inline-block;
				margin-left 	: 1em;

				display 		: flex;
				flex-flow 		: row nowrap;

				.infotitle
				{
					margin-left 	: 0.5em;
					font-style 		: italic;
					opacity 		: 0.6;
					flex-grow 		: 1;
				}
			}

		}

		a, a:visited, a:active
		{
			color 				: white;
			text-decoration 	: none;
		}

		.home
		{
			display 		: inline-block;
			padding 		: 0.3em;
		}


		.button
		{
			cursor 				: pointer;
			height 				: $top-height;
			min-width 			: 5em;
			padding 			: 0.2em 0.8em;
			opacity 			: 0.5;

			.button-icon
			{
				font-size 		: 0.8em;
				margin-right 	: 0.5em;
			}
		}


		.button
		{
			display 			: inline-block;
			border-radius 		: 0px;
			border 				: 0px solid white;
			font-size 			: 1.1em;
			padding 			: 0.6em 1em;
			border-right 		: 1px solid #666;
		}

		.button.invisible
		{
			display 			: none;
		}


		.button.active
		{
			background-color 	: $menu-background-active;
			color 				: $menu-foreground-active;
			opacity 			: 1;
		}

		.button:hover, .button.active:hover, select:hover
		{
			background-color 	: lighten( $menu-background-active, 4 )
		}

		select {
				-webkit-appearance: none;
				-moz-appearance: none;
				padding:   2px;
				overflow: hidden;
				border-radius: 0;
				white-space: nowrap;
				text-overflow: ellipsis;
				position: relative;
				border: 0px solid #D5D5D5;
				background-position: 96% 50%;
				background-repeat: no-repeat;
				background-color: $menu-background;
				font-weight : bold;
				color: $menu-foreground;
				cursor: pointer;
				font-size 	: 1.0em;

				option
				{
					font-size 	: 1.0rem;
				}
		}

		.date
		{
			display 		: flex;
			flex-flow 		: row nowrap;
			text-align 	 	: right;
			overflow 		: hidden;

			span
			{
				margin-right: 1em;
			}
		}

		.toolbutton
		{
			display 		: inline-block;
			margin-left 	: 2em;
			text-align 	 	: right;
			font-style 		: italic;
			cursor 			: pointer;
			text-align 		: right;
		}

	}


	.chart-icon
	{
		position 				: absolute;
		right 					: 0.1em;
		top 					: 6px;
		bottom 					: 0px;
		width 					: 1.5em;
		color 					: #779;
	}


	.bottom.chart-off
	{
		.chart
		{
			display 			: none;
		}
	}

	.bottom.grid-off.sidebar-off
	{
		.table-left
		{
			display 			: none;
		}
	}

	.bottom.grid-off.sidebar-on
	{
		.spreadsheet
		{
			width 				: $tree-width;
			flex-grow 			: 0;
		}
	}

	.bottom.grid-off.sidebar-off
	{
		.spreadsheet
		{
			display 		 	: none;
		}
	}

	.bottom.chart-on
	{
		.spreadsheet
		{
			flex-grow 			: 0;
			margin-right 		: 0px
		}
	}

	.bottom.chart-off
	{
		.spreadsheet
		{
			flex-grow 			: 1;
		}
	}



	.province-master
	{
		background-color : $bottom-background;
		flex-grow 		 : 1;
		position 	     : relative;

		.side-menu
		{
			@include fill-vert;
			@include fit-left-width( $main-menu-width );
			overflow-y : auto;
		}

		.province-data
		{
			@include fill-vert;
			@include fit-right-from( 20em );

			display 			: flex;
			flex-flow 			: row nowrap;

			.notification
			{
				@include fill-shadowbox;
				font-size: 		1.2em;
				background-color: white;
			}
		}
	}

	.side-menu
	{
		position 			: relative;
		@include margin-shadowbox;
		color 				: white;
		background-color 	: $left-background;
		font-size 			: 1em;
		padding 			: 0.5em 0em;

		display 			: flex;
		flex-flow 			: column nowrap;

		overflow-y : auto;


		.filler
		{
			flex-grow 		: 1;
		}

		section[level='2']
		{
			padding 		: 0.2em 0.9em;

			ul
			{
				font-size 	: 0.9em;
			}
		}

		section
		{
			flex-grow 		: 0;
			padding 		: 0em 0.5em;
			margin-bottom 	: 1em;

			h1
			{
				padding 	 : 0em;
				margin 		 : 0em;
				font-size 	 : 1.2em;
				font-weight  : bold;
				cursor 		 : pointer;
			}

			h2
			{
				padding 	 : 0em;
				margin 		 : 0.4em 0.5em;
				font-size 	 : 1.1em;
				font-weight  : bold;
				cursor 		 : pointer;
			}


			h1:hover, h2:hover
			{
				color 		: white;
				opacity 	: 0.7;
			}

			a
			{
				display 			: block;
				padding 			: 0.08em;
				text-decoration 	: none;
				color 				: white;
				width 				: 100%;
			}

			a.selected-entry
			{
				background-color 	: $left-background-selected;
			}

			ul
			{
				padding-left 			: 0.8em;
				margin-top 				: 0.3em;
				margin-bottom 			: 0.8em;
				li
				{
					display 			: block;
					font-family 		: 'Sarabun';
					cursor 				: pointer;
				}
				li:hover
				{
					opacity: 0.7;
				}
			}

			h1:hover
			{
				color 		: inherit;
			}
		}

		.unimplemented
		{
			font-style : italic;
			opacity 	: 0.7;
		}

		.disabled
		{
			opacity 	: 0.3;
		}
	}

/*
	@media( max-aspect-ratio: 1/1)
	{
		.bottom
		{
			@include fit-bottom-from( $top-height );
			display 		: block;
		}

		.filter, .spreadsheet, .chart
		{
			position 	: absolute;
		}

		.bottom.filter-on
		{
			.filter
			{
				position : absolute;
				left 	 : 0px;
				width 	 : 40%;
			}

			.spreadsheet
			{
				position : absolute;
				left 	 : calc( 40% + 8px );
				right 	 : 0px;
			}
		}

		.bottom.filter-off
		{
			.spreadsheet
			{
				position : absolute;
				left 	 : 0px;
				right 	 : 0px;
			}
		}


		.bottom.chart-off
		{
			.spreadsheet, .filter
			{
				top 	: 0px;
				bottom  : 0px;
			}
		}

		.bottom.chart-on
		{
			.spreadsheet, .filter
			{
				top 	: 0px;
				height  : calc( 50% - 8px );
			}

			.chart
			{
				@include fit-bottom-from( 50% );
				position 	: absolute;
			}
		}
	}

	@media( min-aspect-ratio: 1/1)
	{

		*/
		.bottom
		{
			display 			: flex;
			display 			: flex;
			flex-flow 			: row nowrap;
			align-content 		: stretch;
		}

		/*
	}

*/


/* Check this out. The spreadsheet should be gone if the province data contains a chart

@media( min-width: calc( $tree-width + $cell-width + $main-menu-width) )
{
	.bottom.chart-on
	{
		.spreadsheet
		{
			display 		   : none;
		}
	}
}


*/


.bottom, .chart
{
	background-color 		: white;
}

@keyframes level-1 {
  0% {
  	transform: scale( 0.8 ) rotate(0deg);
  	animation-timing-function: linear;
  }

  100% {
   	transform: scale( 0.8 ) rotate(360deg);
   	animation-timing-function: linear;
  }
}

@keyframes level-2 {
  0% {
  	transform: scale( 0.8) rotate(360deg);
  	animation-timing-function: linear;
  }

  100% {
   	transform: scale( 0.8 ) rotate(0deg);
   	animation-timing-function: linear;
  }
}



@keyframes level-3 {
  0% {
  	transform: scale( 0.8 ) rotate(0deg);
  	animation-timing-function: linear;
  }

  100% {
   	transform: scale( 0.8 ) rotate(360deg);
   	animation-timing-function: linear;
  }
}


@keyframes level-4 {
  0% {
  	transform: scale( 0.75 ) rotate(0deg);
  	animation-timing-function: linear;
  }

  100% {
   	transform: scale( 0.75) rotate(360deg);
   	animation-timing-function: linear;
  }
}


@keyframes level-5 {
  0% {
  	transform: scale( 0.9 ) rotate(90deg);
  	animation-timing-function: linear;
  }

  100% {
  	opacity  : 0;
   	transform: scale( 0.9 ) rotate(450deg);
   	animation-timing-function: linear;
  }
}

.pulse:hover {
  animation: none;
}



@keyframes pulse {
  0% {
  	  box-shadow: 0 0 0 0 rgba(0,0,128, 0.6);
  }
  70% {
      box-shadow: 0 0 0 5px rgba(50,50,255, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(50,50,255, 0);
  }
}

@keyframes action-hover {
  0% {
  	  box-shadow: 0 0 0 0 rgba(0,0,128, 0.6);
  }
  70% {
      box-shadow: 0 0 0 5px rgba(50,50,255, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(50,50,255, 0);
  }
}


.bottom
{

	background 	: $bottom-background;
	/* background              : rgb(2,0,36);
    background              : linear-gradient(to right, rgba(2,0,36,1) 0%, rgba(0,0,101,1) 18%, rgba(0,115,83,1) 100%); */


		@include fill;

		.message-zone
		{
			width 				: 25em;
			height 				: 25em;
			text-align 			: center;
			box-shadow 			: inset 2px 2px 5px #888;
			background-color  	: #ddd;
			font-weight 		: 600;
			padding 			: 1.5em 1em;
			margin 				: auto;
		}

		.dropzone
		{
			margin-top 		: 3em;
			font-size 			: 0.8em;
			cursor 				: pointer;
			width 				: 35em;
			height 				: 15em;
			text-align 			: center;
			box-shadow 			: inset 2px 2px 5px #888;
			background-color  	: #eee;
			font-weight 		: 600;
			padding 			: 1.5em 1em;

			animation 			: pulse 3s infinite;

			overflow-x 			: hidden;
			overflow-y 			: auto;


			.single_upload
			{
				text-align 			: left;
				background-color 	: #e0e0e0;
				margin 				: 0.5em;
				padding 			: 0.5em;
				font-size 			: 0.8em;

				.filename
				{
					color 			: #333;
					font-weight 	: bold;
				}

				.progress
				{
					margin-top 		: 0.2em;
					color 			: #555;
				}
			}


			.drop-icon
			{
				margin-top 		: 0.2em;
				font-size 		: 5em;
				img
				{
					width 		: 0.5em;
				}

			}
		}


		.province-tree
		{
			background-color 	: $left-background;
			margin-right 		: 0.5em;

			min-width 			: 10em;

			.province.selected
			{
				.head
				{
					background-color 	: #335;
				}
			}

			.province
			{
				margin 				: 0.5em 1em;
				box-shadow  		: 2px 2px 5px #666;
				background-color 	: white;

				.head:hover
				{
					opacity 		: 0.9;
				}

				.head
				{
					background-color 	: #30707a;
					font-weight 		: 700;
					font-family 		: 'Sarabun';
					font-size 			: 1.2em;
					padding 			: 0.3em 0.5em;
					color 				: white;
					position 			: relative;
					cursor 				: pointer;

					.name
					{
						display 		: inline-block;
					}

					.expand
					{
						display 		: inline-block;
						margin-right 	: 0.5em;
					}
				}

				.excel
				{
					background-color 	: #e0e0e0;
					font-size 			: 1.1em;
					font-weight 		: 400;
					padding 			: 0.3em 0.5em;
					position 			: relative;
					height 				: 2em;
					cursor 			: pointer;

					.download
					{
						position 		: absolute;
						right 			: 10px;
						top 			: 10px;
						font-size 		: 0.8em
					}
				}

				.excel:hover
				{
					background-color : #e0e0e0;
				}

				.scenarios
				{
					.scenario
					{
						background-color 	: white;
						cursor 			: pointer;
						font-weight 	: 400;
						padding 		: 0.5em 01em;
						border-top 		: 1px solid #ddd;
					}

					.scenario:hover
					{
						background-color : #e0e0ff;
					}

					.form-controls
					{
						margin-top 		: 0px;
						cursor 			: pointer;
						background-color : #eee;
						padding 		 : 0.5em;
						.fa
						{
							margin-right : 1em;
						}
					}

					.add-scenario:hover
					{
						box-shadow 		: 0 0 0 rgba(100,100,255, 0.4);
						animation 		: pulse 3s infinite;
						background-color: #ddd;
					}
				}

				.uploads
				{
					.upload
					{
						cursor 			: pointer;
						font-weight 	: 400;
						padding 		: 0.5em 0.7em;
						border-top 		: 1px solid #ddd;
					}

					.upload:hover
					{
						background-color : #e0e0e0;
					}
				}
			}
		}

		.event-list
		{
			@include fill;
			background-color : #eee;

			.event
			{
				height 				: 2em;
				margin 				: 0.3em 0.5em;
				background-color 	: white;
				border 				: 1px solid #ccc;

				.type
				{
					display 		: inline-block;
					width 			: 10em;
				}

				.timestamp
				{
					display 		: inline-block;
					width 			: 20em;
				}
			}
		}

		.tsask-list
		{
			@include fill;
			background-color : #eee;

			.task
			{
				height 				: 2em;
				margin 				: 0.3em 0.5em;
				background-color 	: white;
				border 				: 1px solid #ccc;
				font-size 			: 1.2em;

				.id
				{
					display 		: inline-block;
					width 			: 5em;
				}

				.state
				{
					display 		: inline-block;
					font-weight 	: 700;
					width 			: 20em;
				}
			}
		}


		.filter
		{
			background-color: white;
			width 			: $tree-width;
			overflow-x 		: hidden;
			overflow-y 		: auto;
			flex-grow 		: 0;
		}

		.chart
		{
			.chartcontainer
			{
				position 			: relative;
				background-color 	: #aaa;
				@include 	fill;
				overflow 			: hidden;
			}
			min-width 		: 35vw;
		}



		.spreadsheet.narrow
		{

		}

		.spreadsheet
		{
			flex 					: 1 0 100%;

			@include fill-shadowbox;
			overflow 				: hidden;

			.table-left
			{
				@include 	fit-left-width( $tree-width );

				background-color 	: #f8f8f8;
				overflow 			: hidden;

				.table-head-corner
				{
					@include 		fit-top-height( $header-height );

					background-color : $top-head-background;
					padding 		 : 4px 0.5em;
					color 			 : white;
					select.axis-select
					{
						height 			: 100%;
						display 			: inline-block;
						border-radius 		: 0px;
						padding 			: 0px 5px;
						border 				: 1px solid #666;
						font-size 			: 1.0em;
						background-color 	: #ddd;
						color 				: #000;
					}
				}

				.table-tree-container
				{
					position 			: absolute;
					left 				: 0px;
					bottom 				: 0px;
					top 				: $header-height;

					/* This should hide the scrollbars */
					padding-right 		: 30px;

					overflow-x 			: hidden;
					::-webkit-scrollbar { display: none; }
					overflow-y 			: -moz-scrollbars-none;
					overflow-y 			: scroll;

					scroll-behavior 	: auto;

					.table-tree
					{
						width 			: 100%;
					}
				}
			}


			.chart
			{
				@include 	fit-right-from( $tree-width );
				@include 	fill-vert;
			}

			.table-right
			{
				@include 	fit-right-from( $tree-width );
				.table-head-container
				{
					@include 		fill-horiz;
					@include 		fit-top-height( $header-height );
					background-color 	: $top-head-background;
					overflow-y 			: hidden;
					overflow-x 			: hidden;
					scroll-behavior 	: auto;
					/* This should hide the scrollbars */
					padding-bottom 		: 30px;

				}


				.table-grid-container
				{
					@include 		 fit-bottom-from( $header-height );
					overflow-x 		 : scroll;
					overflow-y 		 : scroll;
					background-color : white;
					scroll-behavior  : auto;

					.table-grid
					{
						@include 	fill;
						@include fill-with-scrollbars;
						scroll-behavior 	: auto;
					}
				}
			}
		}


		.spreadsheet.narrow-header
		{
			.table-left
			{
				width 			 	: $tree-width-narrow;
			}
			.table-right
			{
				left 			 	: $tree-width-narrow;
			}
		}


		.spreadsheet.high-header
		{
			.table-head-corner, .table-head-container, .table-head .value
			{
					height 			: $header-height-high-header;
					font-size  		: 0.9em;
			}

			.table-tree-container, .table-grid-container
			{
				top : $header-height-high-header;
			}
		}

	}
}







.row
{
	display 	: table-row;
}



.table-head
{
	display 		: table;
	table-layout 	: auto;

	.row
	{
		.cell
		{
			background-color 	: $top-head-background;
			color 				: white;
			text-align 			: left;
			display 			: table-cell;

			font-size 			: 0.8em;
			text-align 			: right;

			.value
			{
				font-weight 	: 800;
				font-size 		: 1.1em;
				padding 		: 0.2em 0.7em;
				width 			: $cell-width;
				height 			: $header-height;
				overflow 		: hidden;
				text-overflow   : ellipsis;
			}
		}

		.cell.final
		{
			.value
			{
				width  			: 25rem !important;
			}
		}
	}

}



.table-grid
{
	font-family : 'Source Code Pro', monospace;
	font-weight : 400;
	background-color : white
}

.table-tree, .table-grid
{
	display 	: table;
	table-layout : fixed;
	background-color : white;

	.row
	{
		input, textarea
		{
			color 				: #44d;
			border 				: 0px;
			font-size 			: 1em;
			padding 			: 0.2em;
			vertical-align 		: top;
		}

		input
		{
			font-style 			: italic;
		}

		.cell:hover
		{
			outline 			: 1px solid #ddd;
		}

  	}

}


.row.branch
{
	.cell.row-head
	{
		font-weight 		: 700 !important;
		font-size 			: 1.0em !important;
		color 				: black;
	}
}

.row.leaf
{
	.cell.row-head
	{
		font-weight 		: 400 !important;
		font-size 			: 0.9em !important;
		color 				: #444;
	}
}


.cell.row-head
{

	width 				: $tree-width;
	background-color 	: $row-head-background;
	color 				: $row-head-foreground;
	overflow 		 	: hidden;

	border-right 		: 2px solid #ccc;
	border-bottom 		: 1px solid #eee;


	.cell-contents
	{
		display 		: flex;
		flex-flow 		: row nowrap;
		align-items 	: baseline;

		.warning
		{
			font-size 	: 0.8em;
			color 		: indianred;
			width 		: 1.2em;
			padding 	: 0em 0.1em;
			text-align 	: left;
		}
		.indent
		{
			width 		: 0.5em;
		}

		.indicator
		{
			padding 	: 0;
			width 		: 15px;
		}

		.value
		{
		}

		.value.name
		{
			padding 	: 2px 4px;
			flex-grow 	: 1;
			min-width 	: 15em;
		}

		.value.definition
		{
			padding 	: 2px 4px;
			font-style 	: italic;
			opacity 	: 0.5;
		}

		.value.description, .value.source, value.comment
		{
			flex-grow 		: 0;
			font-size 		: 0.8em;
			opacity 		: 0.9;
			text-align 		: right;
		}

		.value.unit
		{
			padding 		: 2px 2px;
			font-style 		: italic;
			color 			: #555;
			flex-grow 		: 0;
			text-align 		: right;
			margin-left 	: 1em;
			width 			: 10em;
			margin-right 	: 2em;
			font-size 		: 0.8em;
		}

		.chart-icon
		{
			width 			: 1.5em;
			min-width 		: 1.5em;
			flex-grow 		: 1;
		}
	}
}


.cell
{
	display 			: table-cell;
	cursor 				: pointer;
	.value
	{
		padding 		: 1px 0.3em;
	}
}



.spreadsheet.narrow-header
{
	.table-left
	{
		.cell.row-head
		{
			width: $tree-width-narrow !important;
			.cell-contents
			{
				width: $tree-width-narrow !important;
			}
		}
	}
}



.cell.data
{

	border-right 		: 1px solid #f0f0f0;
	border-bottom 		: 1px solid #f0f0f0;
	.value
	{
		width 			: $cell-width;
		text-align 		: right;
	}

	.value.mark
	{
		font-weight 	: bold;
	}
}

.cell.nodata
{
	border-right 		: 1px solid white;
	border-bottom 		: 1px solid white;
	background-color 	: #f8f8f8;
}

.cell.final
{
	background-color 	: white;
	border-right 		: 1px solid #f0f0f0;
	border-bottom 		: 1px solid #f0f0f0;
	.value
	{
		width  			: 20rem;
	}
}

.row-head.cell.focused
{
	color: 				inherit;
}
.cell.focused, .note.focused, .name.focused, .unit.focused
{
	background-color 	: #338;
	color 				: white;
}


.cell.haserrors.focused
{
	background-color 	: #227;
	.errors
	{
		display: none;
	}
}



.cell.focus
{
	color 				: black;
	background-color 	: white;
	border 				: 0px;
	border-bottom 		: 1px dotted black;
	padding 			: 0.2em;
}

.cell-contents
{
	height 				: $cell-height;
	overflow 			: hidden;
	padding 			: 0.2 em;
}


.cell
{
	position 			: relative;

	i.errors
	{
		font-size 		: 0.7em;
		position 		: absolute;
		left 			: 2px;
		top 			: 5px;
	}

	i.errors
	{
		opacity 		: 0.5;
		transition 		: 1s;
	}

	i.errors.warn
	{
		color 			: #ca0;
	}

	i.errors.error
	{
		color 			: red;
	}

	.value.blank
	{
		opacity 		: 0.01;
		transition 		: 1s;
	}

}

.cell:hover
{
	i.errors
	{
		opacity 		: 1;
	}
}

.cell.haserrors
{
	text-align 			: left;
	background-color 	: #fff8f8;
}


.level-1
{
	font-size 			: 1.2em;
	.cell
	{
		background-color 	: $row-head-background;
		color 			 : black;
		font-weight 	 : 800;
	}
}

.level-2
{
	font-size 			: 1.1em;
	.cell
	{
		background-color 	: $row-head-background;
		font-weight 		: 800;
	}
}



.selected
{
	background-color 	: #aac;
	color 				: white;
	font-weight 		: bold;
}


.x
{
	position 			: absolute;
	right 				: 0.5rem;
	top 				: 0rem;
	font-size 			: 1.5rem;
	color 			 	: black;
	z-index 			: 999;
	padding 			: 0px;
	cursor 				: pointer;
}



.icon
{
	margin-right 		: 0.6em;
}



.sankey-main
{
	flex-grow: 1;
	@include 	margin-shadowbox;
	display: 	flex;
	flex-flow: 	column nowrap;
	padding: 0;
	overflow: hidden;



	.sankey-container
	{
		margin-top: 0em;
		flex-grow:  1;
		position: 	relative;
		overflow: 	auto;

		.expression
		{
			height 	: 50px;
			left 	: 0px;
			right 	: 0px;
			position: absolute;
			top 	: 0px;
			background-color : #f8f8f8;
			border-bottom 	: 1px solid #eee;
		}
	}
}

.sankey-menu, .depgraph-menu
{
	flex-grow: 			0;
	background-color: 	$main-submenu-background;
	color: 				$main-submenu-foreground;
	padding: 			0.2em 3em 0.2m 0.8em;
	font-size: 			0.9em;
	flex-basis: 		2.5em;
	display: 			flex;
	flex-flow: 			row wrap;
	align-items: 		center;

	position: 			relative;

	a.download
	{
		display 		: inline-block;
		color 			: white;
		padding 		: 0.4em 1em;
		text-decoration : none;
		background-color : #888;
		min-width 		: 3em;
		margin-left 	: 1.5em;
	}

	a.download:hover
	{
		opacity 		: 0.8;
	}

	.help
	{
		position: absolute;
		right 	: 0.3em;
		top: 	0.1em;
		width   : 1.2em;
		font-size : 1.5em;
		color 	: red;
	}

	.help-finito
	{
		color 	: white;
	}

	.section
	{
		margin 			: 0.3em;
		min-height 		: 1em;
	}


	.item
	{
		display: 	inline-block;
		margin: 	0 1em 0 0;
		opacity: 	0.5;
		cursor: 	pointer;

		a, a:visited, a:active {
			text-decoration 	: none;
			color 				: white;
			opacity: 			1;
		}
	}

	.itemlight
	{
		display: inline-block;
		width: 0.8em;
		height: 0.8em;
		margin: 0em 0.5em;
		background-color: gray;
	}

	.itemlight.selected
	{
		background-color: #4ea;
		box-shadow  : 0px 0px 4px #2a8;
	}

	.item.selected
	{
		background-color: 	lighten( $menu-background, 0.3 );
		opacity: 			1;
	}

	.warning
	{
		margin-left : 2em;
		display 	: inline-block;
		color 		: #fa0;
		font-weight : 800;
		font-size 	: 1.1em;

		i {
			margin-right 	: 0.5em;
		}
	}

	.unit-selector
	{
		display: 		inline-block;
		margin-right: 	1.5em;

		.title
		{
			display: 		inline-block;
			margin-right: 	1em;
		}
	}

	select {
			width: 12em;
			max-width: 100%;
			padding:   5px;
			overflow: hidden;
			border-radius: 0;
			white-space: nowrap;
			text-overflow: ellipsis;
			position: relative;
			border: 0px solid #D5D5D5;
			background-position: 96% 50%;
			background-repeat: no-repeat;
			background-color: $menu-background-light;
			font-weight : bold;
			color: $menu-foreground;
			cursor: pointer;
	}

}


.shadowfill
{
	@include fill-shadowbox;
	overflow: hidden;
}

.flex
{
	display: flex;
}


.province-table
{
	flex-grow	: 0.8;
	max-width 	: 35em;

	.name
	{
		font-weight 	: bold;
		font-size 		: 1.5em;
		margin-bottom 	: 0.8em;
	}

	.uploads, .scenarios, .tasks
	{

		.title
		{
			background-color 	: #f8f8f8;
			font-weight 	: bold;
			font-size 		: 1.1em;
			padding 		: 0.4em;
		}

		.content
		{
			padding 		: 0em;
			margin-bottom 	: 1.5em;
			font-size 		: 0.9em;

			.upload, .scenario, .task
			{
				cursor 				: pointer;
				margin-bottom				: 0;

				padding 			: 0.2em 0.1em;
				border-top 		: 1px solid #eee;

				.date
				{
					padding 		: 0.3em;
				}
			}

			.upload:hover, .scenario:hover
			{
				background-color 	: #f0f0ff;
			}

			.upload
			{
				.date
				{
					font-style 		: italic;
				}
			}
		}
	}
}



.sk_label
{
	font: 10px sans-serif;
}

.sk_header
{
	font: 14px sans-serif;
	font-weight:  bold;
	text-anchor:  middle;
}


.kpi-container
{
	font-size : 0.9em;
	padding 			: 1em;


	.comment
	{
		padding-top : 2em;
	}

	.kpi-table
	{

		border-spacing: 0px;

		box-shadow      : 1px 1px 4px #888;
	    border-collapse: separate;

		th
		{
			text-align 		: right;
			font-weight 	: 700;
			background-color: $menu-background;
			padding 		: 0.6em;
			font-size 		: 1.1em;
			color 			: white;
		}

		td
		{
			border-top 	: 1px solid #ccc;
			padding 		: 0.6em 0.5em;
		}


		.kpi-title
		{
			background-color:  #556;
			color: 				white;
			border-top: 		1px dotted #667;
			max-width: 			25em;
		}

		.kpi-unit
		{
			font-style 		: italic;
			color 			: #222;
			background-color : white;
		}


		.center
		{
			text-align 			: center;
		}

		.kpi-head-subtitle
		{
			font-size 		: 0.9em;
			font-style 		: italic;
		}

		.kpi-value
		{
			background-color: 	#f8f8f8;
			text-align: 		right;
			font-family: 		"Source Code Pro";
			padding-left: 4em;
		}

		.kpi-unit
		{
			background-color: 	#f8f8f8;
		}

	}
}

.dummmy
{
	width 	: 200px;
}

.menucontainer
{
	.menu
	{
		@include fit-top-height( $menu-height );
	}

	.contents
	{
		@include fit-bottom-from( $menu-height );
	}
}




.task-list
{
	display 					: table;

	margin 						: 2em;
	box-shadow      			: 1px 1px 2px #444;

	.task
	{
		display 				: table-row;
		margin 					: 0.3em 0.5em;
		background-color 		: #fff;
		border 					: 1px solid #ccc;
		width 					: 20em;

		.task-field
		{
			display 			: table-cell;
			border-bottom 		: 1px solid #bbb;
			padding 			: 0.2em 1em;
			width 				: 12em;
		}

		.task-head
		{
			display 			: table-cell;
			font-size 			: 0.9em;
			font-weight 		: bold;
			padding 			: 1em 1em;
			background-color 	: #aaa;
			border-bottom 		: 1px solid #888;
		}

		.task-field.short
		{
			width 				: 4em;
		}

		.date
		{
			font-size  			: 0.8em;
			width 				: 32em;

		}
		.province
		{
			background-color	: #d0d0d0;
			font-weight 	 	: bold;
			font-size 			: 1.1em;
			width 				: 8em;
		}

		.status, .scenario
		{
			font-weight 		: 200;
			font-style 			: italic;
			text-transform 		: capitalize;
		}

		.message
		{
			font-size  			: 0.8em;
			font-style 			: italic;
			opacity 			: 0.8;
		}
	}

	.task.status-queued
	{
		.province
		{
			background-color 	: #b0b0ff;
		}
	}

	.task.status-error
	{
		.province
		{
			background-color 	: #ff8080;
		}
	}

	.task.status-cancelled
	{
		.province
		{
			background-color 	: #888;
		}

		.task-field
		{
			opacity: 0.5;
		}
	}


}



.user-list
{
	display 					: table;



	.user:hover
	{
		background-color 		: #f8f8f8;
	}

	.user > *
	{
		border-bottom 			: 1px solid #eee;
		display 				: table-cell;
		padding 				: 0.5em 1em;
		cursor 					: pointer;
	}

	.user
	{
		display 				: table-row;
		background-color 		: #fff;

		.head
		{
			font-weight 		: 800;
			opacity 			: 0.9;
			font-size 			: 1.2em;
			border-bottom 		: 1px solid #444;
		}

		.username
		{
			width 				: 9em;
			font-style 			: italic;
			color 				: #444;
		}

		.name
		{
			font-weight 		: bold;
		}

		.province
		{
		}

		.role, .level
		{
			font-weight 		: 200;
			font-style 			: italic;
		}

	}
}



.kommod-body
{
	font-family : 'Source Code Pro', monospace;

	.line
	{
		margin-bottom 	: 0.5em;
		.segment
		{
			display 	: inline-block;
			padding 	: 0.2em 0.5em;
		}

		.string
		{
			color 		: #444;
		}

		.tab
		{
			width 		: 1em;
			background-color: #f0f0f0;
			border-bottom 	: 1px solid #ddd;
		}

		.varname.error, .value.error
		{
			background-color 	: indianred;
		}

		.varname
		{
			cursor 			 : pointer;
			background-color : #acf;
			opacity 		 : 0.8;
			box-shadow      : 1px 1px 2px #444;
		}

		.value
		{
			cursor 			 : pointer;
			background-color : #fdd;
			opacity 		 : 0.8;
			box-shadow      : 1px 1px 2px #444;
		}

		.value:hover, .varname:hover
		{
			outline 		: 1px solid #888;
			opacity 		: 1;
		}
	}
}

.filename
{
	padding 	: 0.1em 0.5em;
	cursor 		: pointer;
}

.filename:hover
{
	font-weight 	: bold;
}


.fill
{
	@include 		fill;
}



.helptext, .imprint
{
	padding  		: 1.5em;

	& > .head
	{
		padding-bottom  : 0.8em;
		margin 			: 0em 0em 0em 0em;

		.title
		{
			font-size 		: 1.6em;
			font-weight 	: 00;
			color 			: #269;
			margin-bottom 	: 1em;
		}

		.subtitle
		{
			margin-top 		: 2em;
			margin-bottom 	: 0em;
			font-size 		: 1.2em;
			font-weight 	: 600;
			color 			: #269;
			border-bottom 	: 1px solid #ddd;
			padding-bottom 	: 0.2em;
		}
	}

	.title
	{
		font-size 		: 1.8em;
		font-weight 	: 00;
		color 			: #269;
		padding-bottom 	: 0.2em;
		border-bottom 	: 1px solid #269;
		font-weight 	: bold;
	}

	h2
	{
		font-size 		: 1.1em;
		font-weight 	: bold;
		color 			: #269;

		margin-top 		: 2em;
		margin-bottom 	: 0.4em;
	}

	p
	{
		color 			: #444;
	}
}






.imprint
{
	.columns
	{
		max-width 			: 80em;
		.column
		{
			display 		: inline-block;
			width 			: 30%;
			margin-right 	: 2em;
			vertical-align: top;

			.logo
			{
				height 		: 110px;

				img
				{
					max-width 		: 400px;
					width 			: 70%;

					margin 			: 0.5em 0;
				}
			}
		}

		h1
		{
			font-size 		: 1.4em;
			color 			: #269;
			font-weight 	: bold;
			margin-top 		: 2em;
		}


		p
		{
			padding 		: 0;
			margin 			: 0 0 0em 0;
			line-height 	: 1.4em;
		}
	}
}



.frontpage-rows
{
	@include 		fill;

	margin-right 	: 2em;

	overflow-x 		: hidden;
	overflow-y 		: scroll;
	

	& > *
	{
		min-height 	: 50%;
		flex-basis 	: 50%;
		flex-grow 	: 1;
		position 	: relative;
	}

	.top-section
	{

		display 	: flex;
		flex-flow 	: column nowrap;
		overflow 	: hidden;
		flex-grow 	: 1;
		max-height 	: 700px;
		margin-bottom : 2em;

		.content
		{
			display 	: flex;

			flex-flow 	: row nowrap;
			overflow 	: hidden;
			
			.provinces 
			{
				overflow-x 	: none;
				overflow-y 	: scroll;

				flex-basis 	: 40%;
				max-width  	: 25em;
				min-width 	: 20em;
			}

			.province-tree
			{
				background  : white;
			}

			.thailand-map
			{
				margin-left 	: 5em;
				overflow 		: hidden;
				position 		: relative;

				background 		: white !important;
				flex-basis 		: 60%;
				flex-grow 		: 1;

			}
		}
	}

	.chart-section
	{
		flex-grow 	: 1;
		overflow 	: hidden;
		margin-bottom 	: 1.5em;
		height 			: 55em;
		display 		: flex;
		flex-flow 		: column nowrap;

		.chart-container
		{
			overflow 	: none;
			position 	: relative;

			min-height 	: 700px;
		}

		.helpblock
		{
			flex-grow 	: 0;
		}

		.row.charts
		{
			flex-grow 	: 1.2;
			position 	: relative;
		}
	}
}




.thailand-svg
{
	margin 			: 0em 2em 2em 2em;
	width 			: 40%;
	.provincemap
	{
		fill : #777;
		stroke : white;
		stroke-width : 0px;
	}

	.water
	{
		fill : lightblue;
	}

}




.helpblock
{
	padding 	: 0.8em 5em 2em 2em;

	.title
	{
		font-weight : bold;
		font-size 	: 1.2em;
		margin-bottom: 0.8em;
		color		: #269;
	}

	.text
	{
		font-size 	: 1em;
		opacity 	: 0.7;
		font-weight : 400;
		margin-bottom: 0.4em;
	}
}


.subchart.titled
{
	display 	: flex;
	flex-flow 	: column nowrap;

	.title
	{
		flex-grow 	: 0;
	}

	.chart-set
	{
		position 	: relative;
		flex-grow 	: 1;
	}
}

.sankey-container.userlogin
{
	display : flex;
	flex-flow : column nowrap;


	& > *
	{
		position 	: relative;
	}

	& > *
	{
		min-width 	: 400px;
		max-width 	: 100%;
		flex-basis 	: 600px;
		
		flex-grow 	: 1;
	}

	.frontpage-charts
	{
		display 			: flex;
		flex-flow 			: column nowrap;
		overflow-y  		: hidden;
		flex-grow 			: 1;
		min-height 			: 800px;
	}


	.subtitle
	{
		font-size 			: 1.4em;
		margin-bottom 		: 0.2em;

		font-weight 		: 800;
		color 				: #269;

		margin-left 		: 2rem;
	}

	.text
	{
		margin-left 		: 2rem;
		font-size 			: 0.9em;

		margin-bottom 		: 1em;
	}

	.chart-block
	{
		position 			: relative;
		flex-grow 			: 1;

		min-height 			: 800px;

		.chart-container
		{
			min-height 		: 800px;

			.subchart 
			{
				min-height : 800px;
			}
		}
	}

}

.fill-with-title
{
	@include fill;

	background-color : white;
	overflow-y 	: scroll;

	.textblock
	{
	}


	.chartblock
	{
		position 	: relative;
	}
}

.fill-with-title-spreadsheet
{
	@include fill;

	background-color : white;
	overflow-y 	: scroll;

	display 	: flex;
	flex-flow 	: column nowrap;

	.textblock
	{
		flex-grow 	: 0;
	}


	.sheetblock
	{
		flex-grow 	: 1;
		position 	: relative;
	}
}



@keyframes pulse {
    0% { transform: scale(0.9); opacity: 0.7; }
    50% { transform: scale(1.5); opacity: 1; }
    100% { transform: scale(0.9); opacity: 0.7; }
}

.download-icon {

	margin-left : 2em;
	color 		: white !important;
    
    animation: pulse 3s infinite ease-in-out;
}

.smaller
{
	font-size 		: 0.8em;
}