

.svg-master
{
	background-color 	: #88b;
	padding 			: 40px;

	margin 					: 2em;

	display 				: flex;
	flex-flow 				: row wrap;
	overflow 				: hidden;
}

.svg-container
{
	background-color 	: white;
	outline 			: 3px solid #28d;

	flex-basis 			: 50%;
	min-width 			: 20em;
	min-height 			: 20em;
	flex-grow 			: 1;

	padding 			: 0;
	overflow 			: hidden;
}

.empty
{
	background-color 	: #99A;
	flex-basis 			: 50%;
	min-width 			: 20em;
	min-height 			: 20em;
	flex-grow 			: 1;

	padding 			: 0;
}