@import 'stylesheets/layout';
$min-chart-height: 400px;


.inputform.wide
{
	max-width 			: 100em;

	.formdata
	{
		width 			: 100%;

		.line
		{
			.title
			{
				width 	: 12em;
			}
		}
	}
}


.inputform.disabled
{
	.formdata
	{
		opacity 			: 0.3;
	}
}

.inputform
{
	min-width 			: 25em;
	background-color 	: white;
	flex-grow 			: 1;
	font-size 			: 1.1em;
	margin 				: 0.5em 0.5em 5em 0.5em;
	padding 			: 2em;

	overflow-y 			: auto;
	position 			: relative;

	.help-text
	{
		padding 		: 1em;
		font-size 		: 0.9em;
		font-weight 	: normal;
		font-color 		: #aaa;
		margin-bottom 	: 1em;
		background-color	: #f8f8f8;
		max-width 		: 55em;
	}

	& > .head
	{
		padding-bottom  : 0.8em;
		margin 			: 0em 0em 0em 0em;

		.title
		{
			font-size 		: 1.6em;
			font-weight 	: 00;
			color 			: #269;
			margin-bottom 	: 1em;
		}

		.subtitle
		{
			margin-top 		: 2em;
			margin-bottom 	: 0em;
			font-size 		: 1.2em;
			font-weight 	: 600;
			color 			: #269;
			border-bottom 	: 1px solid #ddd;
			padding-bottom 	: 0.2em;
		}
	}



	.formdata
	{
		margin-top 		: 1em;
		font-size 		: 0.95rem;
		text-align 		: left;
		display 		: table;



		.line.gridline > .title
		{
			padding-left 			: 1em;
		}

		.line.gridline > *,
		{
			padding 				: 0em 0.5em;
			input
			{
				border-bottom 		: 0px solid white !important;
				background-color 	: white;
			}

			input.error
			{
				background-color 	: #ffd8d8 !important;
			}
		}

		.line.gridline > .control.inactive,
		{
			padding 				: 0em 0.5em;
			background-color 		: #fafafa !important;
		}


		.line.gridline > .control:hover
		{
			border-color 			: #e8e8e8 !important;
		}

		.line.gridline .title
		{
			border-right 			: 1px solid #e0e0e0;
		}


		.line.gridline:last-child .control
		{
			border-bottom				: 1px solid #e0e0e0;
		}


		.line.gridline:nth-child(even) .control
		{
			background-color 		: #f8f8f8;
			border-top				: 1px solid #e0e0e0;
			border-right 			: 1px solid #e0e0e0;
			padding 				: 0px;
			input
			{
				padding 			: 0px 0.5em;
			}
		}

		.line.gridline:nth-child(odd) > .control
		{
			background-color 		: #ffffff;
			border-top				: 1px solid #e0e0e0;
			border-right 			: 1px solid #e0e0e0;
			padding 				: 0px;
			input
			{
				padding 			: 0px 0.5em;
				background-color 	: #ffffff;
			}
		}


		.line > *
		{
				padding 			: 0.5em;
		}
		.line
		{
			display 		: table-row;
			margin-top 		: 0.3em;

			.title
			{
				display 			: table-cell;
				padding 			: 0.1em 3em 0.1em 0.2em;
				font-weight 		: 400;
				font-family 		: Sarabun;
				color 				: #448;
				font-weight 		: 700;
				min-width 			: 10em;
			}

			.errors
			{
				display 			: table-cell;
			}

			.value, .control, .example
			{
				display 			: table-cell;
				font-family 		: Source Code Pro;
			}

			.value-text, .control
			{
				display 			: table-cell;
				font-family 		: Sarabun;
				color 				: #444;

				b
				{
					font-weight 	: 800;
				}

				ul
				{
					font-size 		: 0.9em;
					font-style 		: italic;
					margin 			: 0.2em 0;
					li
					{
						margin 		: 0;
					}
				}
			}


			.example
			{
				font-style 			: italic;
				font-size 			: 0.9em;

				width 			: 10em;
			}

			.example .value
			{
				color  				: #445;
				float 				: right;
			}

			.control.wide
			{
				input[type='text'],
				input[type='password']
				{
					width 			: 2em;
				}

				select
				{
					width 			: 40em;
				}
			}

			.control
			{
				max-width 			: 75em;
				input[type='text'],
				input[type='password'],
				select
				{
					font-family 	: Sarabun;
					font-weight 	: 600;
					font-size 		: 1.0em;
					border 			: 0px solid white;
					border-bottom 	: 1px solid #888;
					background-color : #f8f8f8;
					max-width 		: 10em;
				}

				input[type='text'],
				input[type='password']
				{
					width 			: 6em;
				}

				select
				{
					width 			: 40em;
				}

				input[type='checkbox']
				{
					width 			: 2em;
					display 		: inline-block;
					margin-left 	: 0px;
					padding 		: 0px;
					margin-right 	: 1em;
				}

				input.error
				{
					background-color 	: #ffd8d8;
					opacity 			: 0.9;
				}
			}

			.control.wide
			{
				input[type='text'],
				input[type='password'],
				select
				{
					width 			: 20em;
					max-width 		: 20em;
				}
			}

			.control.maintitle
			{
				font-size 			: 1.1em;
				text-align 			: center;
				color 				: #444;
			}

			.control.subtitle
			{
				font-size 			: 0.9em;
				font-style 			: italic;
				color 				: #888;
			}

			.span-3
			{
				column-span 		: 3;
				input
				{
					width 			: 100%;
				}
			}


			.span-2
			{
				column-span 		: 2;
			}

			.errors
			{
				font-size 			: 0.9em;
				padding-left 		: 2em;
			}
		}
	}

	.error
	{
		color 			: indianred;
		font-weight 	: bold;
		min-width 		: 10em;
	}

	.message
	{
		color 			: #444;
		margin-bottom 	: 1em;
		font-weight 	: 600;
	}

	.description
	{
		font-size 		: 1em;
		color 			: #444;
		font-weight 	: 400;
	}


	.section
	{
		padding-top 	: 0.2em;
		margin-top 		: 3.5em;
		& > .head
		{
			margin-bottom 	: 1.2em;
			.title
			{
				border-bottom 	: 1px solid #269;
				font-size 		: 1.4em;
				font-weight 	: 600;
				color 			: #269;
			}

			.subtitle
			{
				margin-top 		: 1em;
				font-size 		: 1.1em;
				font-weight 	: 600;
				color 			: #445;
			}
		}

		& > .body
		{
		}
	}


	.spinner
	{
		width : 25px;
		height: 25px;

		.spinner-icon
		{
			margin-top 		 	: 2em;
			font-size 		 	: 1em;
			width 			 	: 5px 5px;
			height 			 	: 1rem;
			text-align 		 	: center;
			position 		 	: absolute;
			left 				: 10;
			transform-origin 	: 0px 0px;
			animation-timing-function: linear;
			animation : level-1 2.5s infinite;
			@include fill;

			i.level1
			{
				color     : #225;
			}
		}
	}
}


.actions
{
	margin 					: 0.5em;
	padding-top 			: 1.5em;
}


.action.link
{
	padding 				: 0.1em 0.3em;
	cursor 					: pointer;
	color 					: #005;
	font-weight 			: bold;
	display 				: inline-block;
	border 					: 1px solid white;
}

.action.link:hover
{
	color 					: #448;
	border 					: 1px solid #e8e8e8;
	background-color 		: #f8f8f8;
}

.form-controls
{
	background-color 	: #f8f8f8;
	margin-top 			: 1.3em;
	padding 			: 0.5em 0em;


	.button, button, input[type='submit'],
	.action
	{

		margin-right 		: 1em;
		cursor 			: pointer;
		padding 		: 0.5em 1em;
		min-width 		: 2em;
		font-weight 	: 400;

		border 			: 0px solid white;
		font-size 		: 1.0em;
		font-weight 	: 600;
		background-color: #005;
		color 			: white;
		padding 		: 0.3em 1em  !important;
		font-family: 		Sarabun;

		.icon
		{
			margin-right: 0.3em;
		}

	}

	.action
	{
		display 		: inline-block;
	}

	.button:hover, button:hover, input[type='submit']:hover, .action:hover
	{
		background-color : darken( #005, 10 );
	}

	.button:disabled, button:disabled, input[type='submit']:disabled, .action:disabled
	{
		background-color : #888;
	}

	.button.inactive, button.inactive, input[type='submit'].inactive, .action.inactive
	{
		color 				: #888;
	}

}




.action.inactive:hover::before {
    content 		: 'Not implemented' ;
    font-size 		: 0.9em;
    position 		: absolute;
    z-index 		: 999;
    white-space 	: nowrap;
	top 			: 35px;
    background  	: #f8f8f8;
    border 	    	: 1px solid #888;
    box-shadow      : 2px 2px 4px #444;
    color 			: #000;
    left 			: -1.5em;
    padding 		: 0.2em 0.5em;
    font-weight 	: 400;
    line-height 	: 24px;
    height 			: 1.5em;

    opacity 		: 1;
    transition 		: opacity 5s ease-out;
    }


.task-run
{
	font-size 		: 1.1em;
	.run-button
	{
		font-size 		: 1.2em;
		display 		: inline-block;
		margin-right 	: 0.8em;
	}

	.title
	{
		font-weight 	: 600;
		display 		: inline-block;
	}

	.body
	{
		padding-top 	: 0.3em;
		font-size 		: 0.9em;
		font-weight 	: 500;
	}
}



.task-result
{
	width 		: 600px;

	.result-table
	{
		font-weight 	: 100;
		td
		{
			min-width 	: 5em;
			padding 	: 0.2em;
			border-bottom 	: 1px solid #d0d0d0;
		}
	}

	.pic
	{
		margin-top 		: 1.5em;
		img
		{
			width 			: 700px;
		}
	}
}


.years
{
	height 				: 5em;
	overflow-y 			: auto
}

.query-display
{
	width 				: 100%;

	&>div
	{
		background-color: #fdfdfd;
		padding 		: 0.5em 1em;
		margin-right 	: 1em;
		margin-top 		: 1em;
		box-shadow 		: 2px 2px 5px #888;
		vertical-align 	: top;
		color 			: #336;
		overflow-y 		: auto;
		overflow-x 		: auto;
		padding-bottom 	: 2em;
	}



	.result
	{
		.json
		{
			font-size 		: 1.2em;
			font-family 	: Source Code Pro;
		}
	}

	pre
	{
		display 			: block;
		font-size 			: 0.8em;
		color 				: #338;
		background-color 	: #f0f0f0;
		padding 			: 0.5em;
		font-family 		: Source Code Pro;
		overflow-x 			: auto;
		margin-bottom 		: 0px;
	}

	.explain
	{
		font-size 			: 0.9em;
	}
}

label
{
	margin-left 		: 0.2em;
}


.copy2clip
{
	margin-top 			: 1px;
	display 			: inline-block;
	float 				: right;

	cursor 			: pointer;
	padding 		: 0.5em;
	min-width 		: 2em;
	font-weight 	: 600;

	padding 		: 0.5em;
	border 			: 0px solid white;
	background-color: #269;
	color 			: white;
	padding 		: 0.5em 0.6em;
}



.query
{
	.section
	{
		border 				: 0px;
		margin-top 			: 1px;
		margin-bottom 		: 2em;
	}
}



.qdata
{
	.table-container
	{
		height 				: 300px;
		width 				: 100%;
		overflow 			: scroll;
		font-size 			: 0.9em;

		.col-header
		{
			font-weight 	: bold;
			color 			: #226;
		}
	}
}


			.menu-icon
			{
				margin-right 	: 0.5em;
			}

			.menu-icon.right
			{
				float 			: right;
			}

			.menu-icon:hover
			{
				color 			: #bbb;
			}

			.menu-separator
			{
				display 		: inline-block;
				width 			: 3em;
			}


.depgraph
{
	@include 	margin-shadowbox;

	margin-left 	: 0px;
	flex-grow 		: 3;
	position 		: relative;
	min-width 		: 35vw;
	overflow 		: hidden;

	.graph-container
	{
		@include 	fill;

		display 	: flex;
		flex-flow 	: column nowrap;

		.depgraph-menu
		{
			color 			: white;
			height 			: $menu-height;
			overflow 		: hidden;
			background-color: $menu-background;
			padding 		: 0.6em 0.2em 0 0.8em;
			flex-grow 		: 0;
			flex-shrink 	: 0;
		}

		.expression-display
		{
			min-height 			: 5rem;
			max-height 			: 80%;
			background-color : #f8f8f8;
			padding 		: 0.5em;
			font-size 		: 0.8em;
			flex 			: 0 0 auto;
			overflow-x 			: hidden;
			overflow-y 			: auto;
			margin-bottom 	: 1em;
			border-bottom 	: 2px solid #888;
			padding-bottom 	: 10px;

		}

		.expression-text
		{
			margin-bottom 	: 0.5em;
			padding 		: 0.2em;
		}

		.graph
		{
			background-color 	: white;
			overflow-x 			: auto;
			overflow-y 			: auto;
			flex 	 			: 1 0 auto;
			position 			: relative;

			.rescale-container
			{
				touch-action: none;
				@include fill;
			}
		}


	}

	.node
	{
		cursor 			: pointer;
	}

	.node:hover
	{
		opacity 		: 0.9;
	}

	.varname
	{
		font-size 		: 12px;
		font-weight 	: bold;
		fill 			: white;
		cursor 			: pointer;
	}

	.value
	{
		font-family : 'Source Code Pro', monospace;
		font-weight : 800;
		fill 			: white;
		cursor 			: pointer;
	}

	.deprect
	{
		cursor 			: pointer;
	}

	.deprect:hover
	{
		fill 			: black;
	}
}




.term
{
	font-size 	: 0.8rem;
	display 	: inline-block;
	vertical-align : middle;

	.expand
	{
		margin-left : 0.8em;
		margin-right : 0.2em;
		margin-top 	: 0.1em;
		font-size 	: 1rem;
		float 		: right;
	}

	.sub-op
	{
		display: inline-block;
		padding : 0.2rem;
		opacity : 0.98;
	}

	.sub-op:hover
	{
		transition : 0.5s;
		outline: 1px solid #ddd;
		opacity: 1;
		cursor: pointer;
	}

	.op
	{
		display : inline-block;
		vertical-align : middle;
		margin 	: 0px 0.3em;
		color 	: #444;
		font-weight: 800;
		font-size : 1.3rem;
	}


	.identifier
	{
		border-radius 	: 0.3rem;
		margin 		: 0.8em 0.2em;
		padding 	: 2px 5px;
		background  : #46a;
	    box-shadow      : 2px 2px 2px #444;
	    color 		: white;

		.value
		{
			border-top 		: 1px solid #eee;
			margin-top 		: 0.5em;
			margin-bottom 	: 0em;
			padding 		: 0.3em 0.2em;
			font-size 		: 0.8rem;
			font-family 	: 'Source Code Pro', monospace;
			font-weight 	: 800;
			opacity 		: 0.8;
		}

		.yearshift
		{
			font-style 		: italic;
			opacity 		: 0.9;
			font-size 		: 0.6rem;
			font-weight 	: 100;
			float 			: right;
		}
	}

	.identifier:hover
	{
		opacity 		: 0.9;
		outline 		: 1px solid #ddd;
		.expand
		{
			opacity 	: 1;
			transition 	: 200ms;
		}
	}
}

.term.expanded
{
	margin 		: 0.8em 0.2em;
	padding: 		0em;
    color 			: black;

	.header
	{
	    background 		: #6ab;
		font-size 	: 0.8rem;
		font-weight : bold;
		padding 	: 0.4em 0.8em;
    	color 			: white;
		.expand
		{
			float 	: right;
		}
	}

	.expansion
	{
		border 		: 1px solid #888;
		padding 	: 0em 0.5em;
	    color 			: black;

		.value
		{
			border-top 		: 1px solid #888;
			margin-top 		: 0.2em;
			margin-bottom 	: 0em;
			padding 		: 0.2em 0em;
			font-size 		: 0.9rem;
			font-family 	: 'Source Code Pro', monospace;
			font-weight 	: 800;
			opacity 		: 0.8;
		}
	}
}


.term.literal, .finalresult
{
	border-radius 	: 0.3em;
	display 	: inline-block;
	vertical-align : middle;
	font-size 	: 1rem;
	margin 		: 1rem 0.2em;
	padding 	: 0.8em 0.8em;
	background  : #54B;
	box-shadow  : 2px 2px 2px #444;
	color 		: white;
}

.term.literal.special
{
	background 	: #B48;
}

.bracket, .equals
{
	font-size 				: 1rem;
	font-weight 			: 700;
	display 				: inline-block;
	vertical-align : middle;
	margin 					: 0px 0.2em;
}




.scenario_table
{
	width 				: 100%;
	font-size 			: 0.9em;
	border-spacing 		: 0px;
    border-collapse 	: separate;

	td
	{
		padding 		: 0.3em 0.5em;

		.download-link
		{
			float 		: right;
		}
		a, a:visited, a:active {
			text-decoration 	: none;
			color 				: #444;
			opacity 			: 1;
			margin-right 		: 0.5em;
		}

	}


	thead, .group_detail .head
	{
		background-color 	: #005;
		color 				: white;
		font-weight 		: bold;

		width 				: 8em;

		i.fa
		{
			margin-right 	: 0.4em;
			width 			: 1;
			display 		: inline-block;
		}

		.content
		{
			display 			: flex;
			flex-flow 			: row nowrap;
			overflow 			: hidden;
			align-items 		: baseline;
			width 				: 10em;
			text-overflow 		: ellipsis;
		}

	}

	.group_detail
	{
		background-color 	: #ddd;
	}

	.group_detail:hover
	{
		background-color 	: #bbb;
	}


	.group_detail td
	{
		border-bottom 		: 1px solid #ccc;
		cursor 				: pointer;

		.button, button, input[type='submit'],
		{
			cursor 			: pointer;
			padding 		: 0.5em 1em;
			min-width 		: 2em;
			font-weight 	: 600;

			border 			: 0px solid white;
			font-size 		: 0.8em;
			background-color: white;
			color 			: #222;
			padding 		: 0.3em 1em;
		}

		button.selected
		{
			background-color 	: #005;
			color 			: white;
		}
	}



	.scenario_detail .head
	{
		background-color 	: #aaa;
		color 				: white;
		font-weight 		: bold;
	}

	.scenario_detail td
	{
		color 				: #444;
		border-bottom 		: 1px solid #eee;
		font-style 			: italic;
	}

	.calcstatus
	{
		text-transform 		: capitalize;
	}

	.trigger.group
	{
		background-color 	: white;
	}


	.trigger
	{
		cursor 				: pointer;
		width 				: 5em;
		background-color 	: #ccc;
		color 				: black;
		padding 			: 0.2em 0.5em;
	}

	.trigger:hover
	{
		color 				: #000;
	}

	.lamp
	{
		display 			: inline-block;
		height 				: 0.9em;
		width 				: 0.9em;
		border 				: 0px solid black;
		background-color    : white;
		margin-right 		: 0.6em;
		margin-top 			: 0.5em;
		border-radius 		: 5%;
	}

	.lamp.lamp-status-waiting
	{
		animation: waiting-pulse 5s linear infinite;

	}

	.lamp.lamp-status-queued
	{
		animation: queued-pulse 2s linear infinite;
	}

	.lamp.lamp-status-error
	{
		background-color 	: #e02;
		transition 			: 250ms;
	}


	.lamp.lamp-status-starting
	{
		animation: waiting-pulse 0.25s linear infinite;
		transition 			: 250ms;
	}


	.lamp.lamp-status-complete
	{
		background-color 	: #2a6;
		transition 			: 250ms;
	}

	.lamp.lamp-status-cancelled
	{
		background-color 	: indianred;
		transition 			: 250ms;
	}

	@keyframes waiting-pulse
	{
	     0% {
	          background-color 	: #28f;
	          opacity 			: 1;
	     }
	     50% {
	          background-color 	: #06d;
	          opacity 			: 0.6;
	     }
	     100% {
	          background-color 	: #28f;
	          opacity 			: 1;
	     }
	}


	@keyframes queued-pulse
	{
	     0% {
	          background-color 	: #82f;
  	          transform 		: scale(1);
  	          border-radius 	: 0px;
  	          opacity 			: 1;

	     }
	     50% {
	          background-color 	: #a4f;
	          transform 		: scale(0.6);
	          border-radius 	: 50%;
	          opacity 			: 0.1;
	     }
	     100% {
	          background-color 	: #82f;
	          transform 		: scale(1);
	          border-radius 	: 0px;
	          opacity 			: 1;
	     }
	}

}



.mover
{
	 animation: dash 5s linear;
}


@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}



.labelled-chart
{
	display 	 : flex;
	flex-flow 	 : column nowrap;

	.textlabel
	{
		flex-grow 	: 0;
		position 	: relative;
		padding 	: 0.5em 1em 1em 5em;
		font-size 	: 0.8em;
	}

	.single-chart
	{
		flex-grow 	: 1;
		position 	: relative;
	}
}


.single-chart
{
	position 			: relative;

	svg, .chart-surface
	{
		@include 		fill;
	}

	.download
	{
		position 		: absolute;
		top 			: 0em;
		left 			: 0.5em;
		width 			: 1em;
		height 			: 1em;
		opacity 		: 0.1;
		color 			: black;
		z-index 		: 500;
	}
}


.single-chart:hover
{
	.download
	{
		opacity 		: 1;
	}
}



	

.chart-set
{
	background-color 		: white;
	display 				: flex;
	flex-flow 				: row wrap;

	.single-chart
	{
		margin 			 	: 0px;
		flex-grow 		 	: 1;
		background-color 	: white;
		min-height 			: $min-chart-height;
	}
}

.fixed-height
{
	position 				: relative;
}

.fixed-height,
.chart-set.fixed-height
{
	height 					: $min-chart-height;
	max-height 				: $min-chart-height;

	.single-chart
	{
		height 				: $min-chart-height;
		max-height 			: $min-chart-height;
	}
}


.chart-container-vertical
{
	display 		: flex;
	flex-flow 		: column nowrap;
	overflow 		: auto;
	background-color: white;
	width 			: 100%;

	.title
	{
		position 		: relative;
		font-weight 	: bold;
		margin-top 		: 0.5em;
		margin-bottom 	: 0.8em;
		font-size 		: 1.3em;
		text-align 		: center;
		height 			: 2.5em;
	}

	.mainchart
	{
		position 	: relative;
		width 		: 100%;
		flex-basis 	: 45%;
		min-height 	: $min-chart-height;
	}
}


.chart-container
{
	background-color 	: white;

	display 			: flex;
	flex-flow 			: row wrap;

	overflow 			: auto;


	.chart-1
	{
		flex-basis 			: 35%;
		flex-grow 			: 1;
		min-width 			: 300px;

		display 			: flex;
		position 			: relative;
		flex-flow 			: column nowrap;
		min-height 			: 800px;
	}

	.chart-3
	{
		flex-basis 			: 65%;
		flex-grow 			: 2;
		min-width 			: 600px;

		display 			: flex;
		flex-flow 			: column nowrap;

		overflow 			: auto;
		min-height 			: 800px;
	}

	.subchart, .mainchart, .topchart
	{
		position 		: relative;
		width 			: 100%;
		height 			: 100%;
		overflow 		: hidden;
	}


	.title
	{
		font-weight 	: bold;
		margin-top 		: 0.5em;
		margin-bottom 	: 0.8em;
		font-size 		: 1.3em;
		text-align 		: center;
	}

	.descriptions
	{
		font-size 		: 0.9em;
		flex-basis 		: 35%;
		flex-grow 		: 1;
		padding 		: 1em;
		overflow 		: auto;

		font-weight 		: 300;

		.headline, b
		{
			font-size  		: 1.1em;
			font-weight 	: 700;
		}
	}

	.topchart
	{
		flex-basis 		: 50%;
		flex-grow 		: 1.5;
	}


	.mainchart
	{
		flex-basis 		: 50%;
		flex-grow 		: 1.5;
		min-height 		: $min-chart-height;
	}

	.subchart
	{
		flex-basis 		: 20%;
		flex-grow 		: 1;
		min-height 		: $min-chart-height;
		margin-top 		: 2em;
	}
}



.detail-link, .detail-text
{
	cursor 			: pointer;
}

.detail-link:hover
{
	stroke 			: #333;
}

.detail-text:hover
{
	fill 			: #333;
}


.chart-container-grid 
{
	display: grid;
	grid-template-columns: 1fr 1fr;

	grid-gap: 0rem;
}


@media screen and (max-width: 1300px) 
{
	.chart-container-grid
	{
		display 	: flex;
		flex-flow 	: column nowrap;

		grid-template-columns : 1fr;
		.title
		{
			grid-column:  1 / span 1;
		}
	}
}



.chart-container-grid
{
	background-color 		: white;
	overflow 				: scroll;

	.chart-grid
	{
		background-color 		: white;

		padding 				: 1em;

		display 				: grid;

		grid-auto-rows 			: minmax( 30vh, 30vh);
		grid-column-gap 		: 0px;
		grid-row-gap 			: 0px;


		.single-chart
		{
			margin 			 	: 0px;
		}
	}

	@media screen and (min-width: 0px) and (max-width:  1500px) 
	{
		.chart-grid
		{
			grid-template-columns : 1fr;
		}
	}

	@media screen and (min-width: 1501px) and (max-width:  1600px) 
	{
		.chart-grid
		{
			grid-template-columns : 1fr 1fr;
		}
	}


	@media screen and (min-width: 1601px) and (max-width:  1400px) 
	{
		.chart-grid
		{
			grid-template-columns : 1fr;
		}
	}


	@media screen and (min-width: 1501px) 
	{
		.chart-grid
		{
			grid-template-columns 		: 1fr 1fr;
		}
	}


	.title
	{
		grid-column 	: 1 / span 2;
		font-size 		: 1.7em;
		color 			: #269;
		font-weight 	: bold;
		padding 		: 0.2em 1.2em 0.8em 1.2em;
		border-top 		: 1px solid #aaa;
	}

	.descriptions
	{
		font-weight 		: 300;
		margin 		: 2em;
		background-color 	: white;

		table
		{
			background-color 	: #f8f8f8;
		}

		.headline, b
		{
			font-size  		: 1.1em;
			font-weight 	: 700;
		}
	}



	.subchart, .description
	{
		position 			: relative;
	}

	table
	{
		width 		: 100%;
		font-size 	: 0.8em;
		background-color 	: #f0f0f0;
		border-spacing: 2px;
    	border-collapse: collapse;

		th
		{
			border 		: 0 solid white;
			border-bottom 		: 1px solid #ccc;
			border-left 		: 1px solid #ccc;
			color 				: black;
			font-size 			: 1.1em;
			font-weight 		: bold
		}

		thead
		{
			tr:nth-child(1)
			{
				background-color 	: #aaa;
				font-size 			: 1.1em;
				th, td
				{
					padding 			: 0.5em;
				}
			}
			tr:nth-child(2)
			{

				background-color 	: #ccc;
			}
		}

		td
		{
			padding 			: 0.3em 0.5em;
			border-bottom 		: 1px solid white;
			border-left 		: 1px solid white;
		}
	}
}



.year-ref-table
{
	margin 			: 1em;
	font-size 		: 0.9em;
	color 			: #222;
	opacity 		: 0.8;


	td,th
	{
		padding 			: 0.2em 0.5em;
	}

	th
	{
		font-weight 		: bold;
		padding-bottom 		: 0.5em;
		text-align 			: left;
	}

	td
	{
		font-weight 		: normal;
		color 				: #557;
	}

	td.created
	{
		font-style 		: italic;
	}

	td.select
	{
		font-weight 	: bold;
		opacity 		: 1;
		color 			: black;
	}
}




.errors
{
	color 			: indianred;
	font-weight 	: bold;
	min-width 		: 10em;

	padding-bottom 	: 1.5em;
}




.ee
{
	font-size 		: 0.95em;
	background 		: white;

	padding 		: 0em;
	margin-bottom 	: 3em;

	.contents
	{
		padding 	: 0.5em;
	}

	.head
	{
		padding 	: 0.5em;
		font-size 	: 1.3em;
		font-weight : bold;
		color 		: #158;
	}

	.formdata
	{
	}

	.formdata .control
	{
		width 		: 10em;
	}

	.formdata .title
	{
		width 		: 20em;
	}

	select
	{
		font-family 	: Sarabun;
		font-weight 	: 600;
		font-size 		: 1.0em;
		border 			: 0px solid white;
		border-bottom 	: 1px solid #888;
		background-color : #f8f8f8;
		max-width 		: 10em;
	}

	.control.title
	{
		font-size 	: 0.8em;
		opacity 	: 0.8;
		padding-left : 0.8em !important;
		font-style 	: italic;
		color 		: #888 !important;
	}

	input, select
	{
		background 	: white;
	}
}


.help-master
{
	position : fixed !important;
	@include fill;
	z-index 		: 1000;
}

.help-master-background
{
	position : fixed;
	@include fill;
	background : #888;
	opacity 	: 0.6;
	z-index 	: 1001;
}

.help-overlay
{
	position 		:fixed;

	top 			: 8em;
	bottom 			: 8em;
	left 			: 8em;
	right 			: 8em;
	box-shadow 		: 3px 3px 5px #888;



	background-color 	: white;

	z-index 		: 1002;

	.title
	{
		position : absolute;

		top 	 : 0px;
		height 	 : 35px;
		left 	 : 0px;
		right 	 : 0px;



		background 	: $menu-background;
		color 		: $menu-foreground;

		padding 	: 0.3em 1em;
		font-size 		: 1.2em;
		font-weight 	: 700;
		overflow 		: hidden;
		border-bottom   : $menu-border-width solid $menu-border;


		display 		: flex;
		flex-flow 		: row nowrap;
		align-items 	: baseline;

		.close-button
		{
			position 	: absolute;
			right 		: 0.5em;
			top 		: 0.5em;
			bottom 		: 0.3em;
			width 		: 2em;
			text-align 	: right
		}

	}

	.body
	{
		position 	: absolute;
		top 	 	: 35px;
		bottom 	 	: 0px;
		left 	 	: 0px;
		right 	 	: 0px;

		color 		: black;

		.help-text
		{
			position 	: absolute;
			left 		: 0px;
			right 		: 0px;
			top			: 0px;
			bottom 		: 0px;

		}
	}

}